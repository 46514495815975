/** @jsxRuntime classic */
import { jsx } from "@emotion/react";
import React, { ReactNode } from "react";

import getMergedStyles from "../../helpers/getMergedStyles";
import styles from "./List.styles";

interface ItemProp {
  content: ReactNode | string;
  onClick?: () => void;
}

interface Props {
  isNumbered?: boolean;
  items: ItemProp[];
  styles?: object;
  variant?: string | string[];
}

export const List = ({
  isNumbered = false,
  items,
  styles: parentStyles = {},
  variant = "",
}: Props) => {
  const { root, listItem }: { [key: string]: any } = getMergedStyles(
    styles,
    parentStyles,
    variant
  );

  return (
    <ul css={root}>
      {items.map((item, index) => {
        const { content, onClick } = item;
        return (
          <li key={index} css={listItem} {...(onClick ? { onClick } : {})}>
            {content}
          </li>
        );
      })}
    </ul>
  );
};
